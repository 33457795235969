<style lang="scss">
</style>
<!--eslint-disable-->
<template>
  <div class="users-page">

    <b-card class="" title="Possessions">
      <b-card-text>This page shows all possessions in your enterprise. You can manage and create possessions directly
        from this page.
      </b-card-text>
    </b-card>

    <b-card class="mb-0" no-body>
      <PossessionsTable :default-filters="{}"/>
    </b-card>
  </div>
</template>
<!--eslint-enable-->

<script>
import PossessionsTable from "@/views/possessions/sections/PossessionTable.vue";

export default {
  name: 'Possessions',
  components: {
    PossessionsTable,
  },
};
</script>
